import React from 'react' 
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const Footer = () => {
  return (
     <>
     <div className="footer-sec">
     <div className="container">
     <div className="row">
     <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-12 col-sm-12">
     <h3>Delhi Police Public School Kingsway, Camp <span> CBSE Affiliation NO:2750173 , School ID: 1309275</span></h3>	
     <p><i className="fas fa-mobile-alt"></i> <Link to="tel:+91 8851038881"> +91 8851038881</Link></p>
     <p> <Link to="#" target="_blank"><i className="fas fa-map-marker-alt"></i>Police Lines, GTB Nagar, Delhi, 110009</Link></p>
     <p><i className="far fa-envelope"></i> <Link to="mailto:dpps.2005@gmail.in">dpps.2005@gmail.com</Link></p>	
     <div className="social">
     <Link to="#" target="_blank"><i className="fa-brands fa-facebook-f"></i></Link>
    
     <Link to="#" target="_blank"><i className="fa-brands fa-youtube"></i></Link>
     <Link to="#" target="_blank"><i className="fa-brands fa-twitter"></i></Link>
     <Link to="#" target="_blank"><i className="fa-brands fa-instagram"></i></Link>
     </div>
     </div>
     <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-12 col-sm-12">
     <h3>Useful Links</h3>
     <ul>
     <li> <Link to="#"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Career</Link></li>		
     <li> <Link to="#"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Admission</Link></li>	
     <li> <Link to="#"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Fee</Link></li>
     <li> <Link to="/Pphelp"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Portal Help?</Link></li>	
     <li><Link to="#"><i className="fa fa-angle-double-right" aria-hidden="true"></i> ERP Software</Link></li>	
     </ul>	
     </div>
     <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12">
     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1749.8386911865027!2d77.19827825678459!3d28.699295793881685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfdf7eb757b71%3A0xf88f58f3d394fa!2sNew%20Police%20Line%20-%20Delhi%20Police%20(Kingsway%20Camp%2C%20Delhi)!5e0!3m2!1sen!2sin!4v1715066552633!5m2!1sen!2sin" width="100%" height="250" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>	
     </div>	
     </div>
     </div>
     <div className="clr"></div>
     </div>	
     <div  className="ftr-copy">
        <p>© Copyright 2024  - 23 By Delhi Police Public School Kingsway,Camp - Designed &amp; Maintained By <Link className="link" to="https://www.entab.in/" target="_blank"> Entab Infotech : CampusCare® </Link></p>
     </div>
     </>
  )
}

export default Footer
