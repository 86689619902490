import React, { useState, useEffect} from 'react';
import { Link,useLocation  } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const Header = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    const [isOffcanvasVisible, setOffcanvasVisible] = useState(false);

    const toggleOffcanvas = () => {
      setOffcanvasVisible(!isOffcanvasVisible);
    };
  return (
     <>
     <div className="header-sec">
     <div className="container">
     <div className="header-btm">
      <Link to="/">
      <LazyLoad><img src="https://webapi.entab.info/api/image/DPPSK/public/Images/logo.png" className="cubeHide" alt="Delhi Police Public School Kingsway,Camp"/></LazyLoad></Link>
     <ul>
     <li> <Link to="tel:+91 8851038881"><i className="fa fa-phone ui default"></i><span>+91 8851038881</span></Link></li>
     <li> <Link to="mailto:dpps.2005@gmail.in"><i className="fa fa-envelope ui default"></i><span>dpps.2005@gmail.com </span></Link></li>
     <li className="fee-latest"> <Link to="https://dppsk.campuscare.cloud/" target="_blank"><i className="fas fa-money-bill-alt"></i><span>Online Fee Payement</span></Link></li>
     </ul>
     </div>
     </div>
     </div>
     <div className="menu-sec">
             <div className="container">
                 <nav className="navbar navbar-expand-lg navbar-light navbar-dark shadow">
                     <div className="container-fluid">
                         <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                             data-bs-target="#navbar-content">
                             <div className="hamburger-toggle">
                                 <div className="hamburger">
                                     <span></span><span></span><span></span>
                                 </div>
                             </div>
                         </button>
                         <div className="collapse navbar-collapse" id="navbar-content">
                             <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
                                 <li className="nav-item">
                                   <Link className="nav-link " aria-current="page" id="home" to="/">
                                     <i className="fa-solid fa-home"></i>Home</Link></li>



<li className="nav-item"><Link className="nav-link " aria-current="page" id="home" to="/"><i className="fa-solid fa-home"></i>About Us</Link></li>
<li className="nav-item"><Link className="nav-link " aria-current="page" id="home" to="/"><i className="fas fa-university"></i>Campus</Link></li>
<li className="nav-item"><Link className="nav-link " aria-current="page" id="home" to="/"><i className="fa-solid fa-book"></i>ACADEMICS</Link></li>
<li className="nav-item"><Link className="nav-link " aria-current="page" id="home" to="/"><i className="fa-solid fa-football"></i>Beyond Academics</Link></li>
<li className="nav-item"><Link className="nav-link " aria-current="page" id="home" to="/"><i className="fa-solid fa-person-chalkboard"></i>Examination</Link></li>

<li className="nav-item"><Link className="nav-link " aria-current="page" id="home" to="/"><i className="fa-solid fa-address-book"></i>Contact Us</Link></li>

                                 {/* <li className="nav-item dropdown dropdown-mega position-static"><div className="nav-link dropdown-toggle"
                                     to="#" data-bs-toggle="dropdown" data-bs-auto-close="outside"><i className="fa-solid fa-user-group">
                                     </i>About Us</div>
                                     <div className="dropdown-menu shadow">
                                         <div className="mega-content">
                                             <div className="list-group">
                                                 <Link className="list-group-item" to="#">Our School, Motto & Philosophy</Link>
                                                 <Link className="list-group-item" to="#">Affiliation</Link> <Link className="list-group-item"
                                                     to="#">Apex Body</Link> <Link className="list-group-item" to="#">
                                                         About Principal </Link><Link className="list-group-item" to="#">DPPS Family</Link>
                                                 <Link className="list-group-item" to="#">History</Link> <Link className="list-group-item"
                                                     to="#">Media Highlights</Link>
                                             </div>
                                         </div>
                                     </div>
                                 </li>
                                 <li className="nav-item dropdown dropdown-mega position-static"><div className="nav-link dropdown-toggle"
                                     to="#" data-bs-toggle="dropdown" data-bs-auto-close="outside"><i className="fas fa-university">
                                     </i>CAMPUS</div>
                                     <div className="dropdown-menu shadow">
                                         <div className="mega-content">
                                             <div className="list-group">
                                                 <Link className="list-group-item" to="#">Campus Tour</Link> <Link className="list-group-item"
                                                     to="#">Facility &#038; Infrastructure</Link> <Link className="list-group-item"
                                                         to="#">Safety &#038; Security</Link>
                                             </div>
                                         </div>
                                     </div>
                                 </li>
                                 <li className="nav-item dropdown dropdown-mega position-static"><div className="nav-link dropdown-toggle"
                                     to="#" data-bs-toggle="dropdown" data-bs-auto-close="outside"><i className="fa-solid fa-book">
                                     </i>ACADEMICS</div>
                                     <div className="dropdown-menu shadow">
                                         <div className="mega-content">
                                             <div className="list-group">
                                                 <Link className="list-group-item" to="#">Syllabus/Course Outline</Link>
                                                 <Link className="list-group-item" to="#">Result</Link> <Link className="list-group-item"
                                                     to="#">Curriculum</Link> <Link className="list-group-item" to="#">
                                                         Academic Calendar</Link>
                                             </div>
                                         </div>
                                     </div>
                                 </li>
                                 <li className="nav-item dropdown dropdown-mega position-static"><div className="nav-link dropdown-toggle"
                                     to="#" data-bs-toggle="dropdown" data-bs-auto-close="outside"><i className="fa-solid fa-football">
                                     </i>Beyond Academics</div>
                                     <div className="dropdown-menu shadow">
                                         <div className="mega-content">
                                             <div className="list-group">
                                                 <Link className="list-group-item" to="#">Medical Record</Link> <Link className="list-group-item"
                                                     to="#">School Transport</Link> <Link className="list-group-item" to="#">
                                                         Fees Rules</Link> <Link className="list-group-item" to="#">School Uniform</Link>
                                                 <Link className="list-group-item" to="#">PTM</Link> <Link className="list-group-item" to="#">
                                                     House Systems</Link> <Link className="list-group-item" to="#">Alumni</Link>
                                             </div>
                                         </div>
                                     </div>
                                 </li>
                                   
                                 <li className="nav-item dropdown dropdown-mega position-static"><div className="nav-link dropdown-toggle"
                                     to="#" data-bs-toggle="dropdown" data-bs-auto-close="outside"><i className="fa-solid fa-person-chalkboard">
                                     </i>Examination</div>
                                     <div className="dropdown-menu shadow">
                                         <div className="mega-content">
                                             <div className="list-group">
                                                 <Link className="list-group-item" to="#">Datesheet</Link> <Link className="list-group-item"
                                                     to="#">Syllabus</Link>
                                             </div>
                                         </div>
                                     </div>
                                 </li>
                                 
                                 <li className="nav-item dropdown dropdown-mega position-static ph-menu" ><div className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown" data-bs-auto-close="outside"><i className="fa-solid fa-link"></i>Useful Links</div>
                                     <div className="dropdown-menu shadow">
                                         <div className="mega-content">
                                             <div className="list-group">
                                             <Link className="list-group-item" to="/Pphelp"> Parent Portal</Link> 
                                                 <Link className="list-group-item" to="#">Events</Link> 
                                                 <Link className="list-group-item" to="#">CBSE</Link> 
                                                 <Link className="list-group-item" to="#">Vacancy</Link>
                                                 <Link className="list-group-item" to="/Tenders">Tenders</Link>
                                                 <Link className="list-group-item" to="#">Branches</Link> 
                                                 <Link className="list-group-item" to="#">Holiday Homework</Link> 	
                                             </div>
                                         </div>
                                     </div>
                                 </li> 
                                                 <li className="nav-item"><Link className="nav-link " aria-current="page" id="contact" to="#">
                                     <i className="fa-solid fa-address-book"></i>Contact Us</Link></li>  
                                 */}


                 
                             </ul>
                         </div>
                     </div>
                 </nav>
                 <ul>
                 <li className="quick">
                 <div
                 className="icon-one"
                 onClick={toggleOffcanvas}
                 aria-controls="offcanvasRight"
               >
                 <div className="hamburger hamburger-one"></div>
               </div>
         
               <div
                 className={`offcanvas offcanvas-end ${isOffcanvasVisible ? 'show' : ''}`}
                 tabIndex="-1"
                 id="offcanvasRight"
                 aria-labelledby="offcanvasRightLabel"
                 style={{ visibility: isOffcanvasVisible ? 'visible' : 'hidden' }}
               >
                 <div className="offcanvas-header">
                   <h5 id="offcanvasRightLabel">Menu</h5>
                   <button
                     type="button"
                     className="btn-close text-reset"
                     onClick={toggleOffcanvas}
                     aria-label="Close"
                   ></button>
                 </div>
                 <div className="offcanvas-body">
                   <ul>
                     <li>
                       <Link to="/Pphelp">
                         <i className="bi bi-people-fill"></i>Parent Portal
                       </Link>
                     </li>
                     <li>
                       <Link to="/Tenders">
                         <i className="fa-solid fa-gavel"></i>Tenders
                       </Link>
                     </li>
                     <li><Link to="/Cbse"><i class="fa-solid fa-book"></i>CBSE</Link></li>
                   </ul>
                 </div>
               </div>
                 </li>
                 </ul>
             </div>
         </div>
     </>
  )
}

export default Header
