import React from 'react'
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import LazyLoad from 'react-lazyload';
const HomeNews = () => {
  const newsoptions = {
    margin: 30,
    dots:false,
    responsiveClass: true,
    autoplay: true,
    smartSpeed: 1000,
    nav: true,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items:1,
        },
        600: {
            items: 2,
        },
        700: {
            items:2,
        },
        1000: {
            items:2,

        },
        1200: {
            items:2,

        }
    },
};
  return (
     <> 
     <div className="row">
     <OwlCarousel className='owl-theme' loop margin={10} nav {...newsoptions}>
     <div className="item">
     <div className="newssecbox">
     <div className="newssecboxitem">
          <div className="newssecboxdate"><h3>01</h3></div>
         <div className="newssecitem">
             <p className="newssecitemtitle">News Title</p>
             <p className="newssecitemdesc">Stay Tunned For More Updates</p>
         </div>
         <Link to="#"> Attachment </Link>
     </div>
</div>
      </div>
      <div className="item">
      <div className="newssecbox">
      <div className="newssecboxitem">
           <div className="newssecboxdate"><h3>02</h3></div>
          <div className="newssecitem">
              <p className="newssecitemtitle">News Title</p>
              <p className="newssecitemdesc">Stay Tunned For More Updates</p>
          </div>
          <Link to="#"> Attachment   </Link>
      </div>
</div>
      </div>
      <div className="item">
      <div className="newssecbox">
      <div className="newssecboxitem">
           <div className="newssecboxdate"><h3>03</h3></div>
          <div className="newssecitem">
              <p className="newssecitemtitle">News Title</p>
              <p className="newssecitemdesc">Stay Tunned For More Updates</p>
          </div>
          <Link to="#"> Attachment </Link>
      </div>
</div>
      </div>
        </OwlCarousel> 
     </div>
     </>
  )
}

export default HomeNews