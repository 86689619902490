import React from 'react'
import Header from'../Component/Header'
import Footer from '../Component/Footer'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const Cbse = () => {
  return (
   <>
    <Header/>
    <section class="inr-slider">
                <div class="container-fluid">
                </div>
     </section>
     <section class="quicklink">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <ul>
                            <li><Link to="/">
                            <LazyLoad><img src="https://webapi.entab.info/api/image/DPPSK/public/Images/home.png" class="img-fluid" alt=" Delhi Police Public School"/></LazyLoad>
                            </Link></li>
                             
                            <li><Link to="Cbse" class="active">Mandatory Public Disclosure</Link></li> 
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="inrhgt campus">
            <div class="breadcrumb">
                <ul class="list-inline">
                    <li><Link to="/">Home</Link></li>
                    <li>Useful Links</li>
                    <li>Mandatory Public Disclosure</li>
                </ul>
            </div>
            
            <div class="row">
            <div class="col-md-12">
            <h2 class="mainhead">Mandatory Public Disclosure</h2> 
               <div class="mandatorybtn">
               <Link to="https://webapi.entab.info/api/image/DPPSK/public/pdf/Mandatory-Public-Disclosure.pdf" target="_blank">  Mandatory Public Disclosure</Link>
               </div>
<p><b>A: GENERAL INFORMATION</b></p>


<div class="tablescroll table-responsive">
   <table class="table table-bordered table-striped">
     <thead>
         <tr>
             <th class="text-center">S. No. </th>
             <th class="text-center">INFORMATION</th>
             <th class="text-center">DETAILS</th>
         </tr>
     </thead>
     <tbody class="text-center">
         <tr>
             <td>1 </td>
             <td>NAME OF THE SCHOOL </td>
             <td>DELHI POLICE PUBLIC SCHOOL KINGSWAY, CAMP </td>
         </tr>
         <tr>
             <td>2 </td>
             <td>AFFILIATION NO.(IF APPLICABLE) </td>  
             <td>2750173</td>
         </tr>
         <tr>
             <td>3 </td>
             <td>SCHOOL CODE (IF APPLICABLE) </td>
             <td>1309275</td>
         </tr>
         <tr>
             <td>4 </td>
             <td>COMPLETE ADDRESS WITH PIN CODE</td>
             <td>Delhi Police Public School Kingsway, Camp , Police Lines, GTB Nagar, Delhi, 110009</td>
         </tr>
         <tr>
             <td>5</td>
             <td> PRINCIPAL NAME &amp; QUALIFICATION </td>
             <td>-</td>
         </tr>
         <tr>
             <td>6</td>
             <td> SCHOOL EMAIL ID</td>
             <td><Link to="mailto:dpps.2005@gmail.com">dpps.2005@gmail.com</Link></td>
         </tr>
         <tr>
             <td>7 </td>
             <td>CONTACT DETAILS (LANDLINE/MOBILE)</td>
             <td><Link to="tel:+91 8851038881"> +91 8851038881</Link></td>
         </tr>
     </tbody>
 </table>
</div>
<div class="clr20"></div>
<p><b>B: DOCUMENTS AND INFORMATION</b></p>
<div class="tablescroll table-responsive">
   <table class="table table-bordered table-striped">
     <thead>
         <tr>
             <th class="text-center">S. No. </th>
             <th class="text-center">DOCUMENTS/INFORMATION </th>
             <th class="text-center">UPLOAD DOCUMENTS</th>
         </tr>
     </thead>
     <tbody class="text-center">
         <tr>
             <td>1 </td>
             <td>COPIES OF AFFILIATION/UPGRADATION LETTER AND RECENT EXTENSION OF AFFILIATION, IF ANY </td>
             <td><Link to="#" target="_blank">View</Link></td>
         </tr>
         <tr>
             <td>2 </td>
             <td>COPIES OF SOCIETIES/TRUST/COMPANY REGISTRATION/RENEWAL CERTIFICATE, AS APPLICABLE </td>
             <td><Link to="https://webapi.entab.info/api/image/DPPSK/public/pdf/Society-Registrar-Certificate.pdf" target="_blank">View </Link></td>
         </tr>
         <tr>
             <td>3 </td>
             <td>COPY OF NO OBJECTION CERTIFICATE (NOC) ISSUED, IF APPLICABLE, BY THE STATE GOVT./UT</td>
             <td><Link to="#" target="_blank">View </Link></td>
         </tr>
         <tr>
             <td>4 </td>
             <td>COPIES OF RECOGNITION CERTIFICATE UNDER RTE ACT, 2009, AND IT’S RENEWAL IF APPLICABLE</td>
             <td><Link to="https://webapi.entab.info/api/image/DPPSK/public/pdf/Recognition-Upgradation-Certificates.pdf" target="_blank">View</Link></td>
         </tr>
         <tr>
             <td>5 </td>
             <td>COPY OF VALID BUILDING SAFETY CERTIFICATE AS PER THE NATIONAL BUILDING CODE</td>
             <td><Link to="https://webapi.entab.info/api/image/DPPSK/public/pdf/building-safety-certificate.pdf" target="_blank">View</Link></td>
         </tr>
         <tr>
             <td>6 </td>
             <td>COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE COMPETENT AUTHORITY</td>
             <td><Link to="https://webapi.entab.info/api/image/DPPSK/public/pdf/Fire-Safety-Certificate.pdf" target="_blank">View</Link></td>
         </tr>
         <tr>
             <td>7 </td>
             <td>COPY OF THE DOE CERTIFICATE </td>
             <td><Link to="https://webapi.entab.info/api/image/DPPSK/public/pdf/DOE-Certificate.pdf" target="_blank">View </Link></td>
         </tr>
         <tr>
             <td>8 </td>
             <td>COPIES OF VALID WATER, HEALTH AND SANITATION CERTIFICATES </td>
             <td><Link to="https://webapi.entab.info/api/image/DPPSK/public/pdf/Hydel-Laboratories.pdf" target="_blank">View</Link></td>
         </tr>
     </tbody>
 </table>
</div>

<div class="clr20"></div>
<p><b>C: RESULT AND ACADEMICS</b></p>
<div class="tablescroll table-responsive">
   <table class="table table-bordered table-striped">
     <thead>
         <tr>
             <th class="text-center">S. No. </th>
             <th class="text-center">DOCUMENTS/INFORMATION </th>
             <th class="text-center">UPLOAD DOCUMENTS</th>
         </tr>
     </thead>
     <tbody class="text-center">
         <tr>
             <td>1 </td>
             <td>FEE STRUCTURE OF THE SCHOOL </td>
             <td><Link to="https://webapi.entab.info/api/image/DPPSK/public/pdf/fee-structure.pdf" target="_blank">View </Link></td>
         </tr>
         <tr>
             <td>2 </td>
             <td>ANNUAL ACADEMIC CALANDER </td>
             <td><Link to="https://webapi.entab.info/api/image/DPPSK/public/pdf/Annual-Calender.pdf" target="_blank">View</Link></td>
         </tr>
         <tr>
             <td>3 </td>
             <td>LIST OF SCHOOL MANAGEMENT COMMITTEE (SMC) </td>
             <td><Link to="https://webapi.entab.info/api/image/DPPSK/public/pdf/SMC-List.pdf" target="_blank">View</Link></td>
         </tr>
         <tr>
             <td>4 </td>
             <td>LIST OF PARENTS TEACHERS ASSOCIATION (PTA) MEMBERS</td>
             <td><Link to="https://webapi.entab.info/api/image/DPPSK/public/pdf/PTA.pdf" target="_blank">View</Link></td>
         </tr>
         <tr>
             <td>5 </td>
             <td>LAST THREE-YEAR RESULT OF THE BOARD EXAMINATION AS PER APPLICABLILITY</td>
             <td><Link to="https://webapi.entab.info/api/image/DPPSK/public/pdf/CBSE-Result.pdf" target="_blank">View</Link></td>
         </tr>
     </tbody>
 </table>
</div>
  {/*             <p>RESULT CLASS: X</p>

<div class="tablescroll table-responsive">
   <table class="table table-bordered table-striped">
     <thead>
         <tr>
             <th class="text-center">S. NO </th>
             <th class="text-center">YEAR </th>
             <th class="text-center">NO. OF REGISTERED STUDENTS</th>
             <th class="text-center">NO. OF STUDETNS PASSED</th>
             <th class="text-center">PASS PERCENTAGE</th>
             <th class="text-center">REMARKS</th>

         </tr>
     </thead>
     <tbody class="text-center">
         <tr>
             <td>1</td>
             <td>2022</td>
             <td>152 </td>
             <td>152 </td>
             <td>100% </td>
             <td>-</td>
         </tr>
         <tr>
             <td>2</td>
             <td>2023</td>
             <td>170 </td>
             <td>169 </td>
             <td>99.4% </td>
             <td>-</td>
         </tr>
     </tbody>
 </table>
</div>

                 <p>RESULT CLASS: XII</p>

<div class="tablescroll table-responsive">
   <table class="table table-bordered table-striped">
     <thead>
         <tr>
             <th class="text-center">S. NO </th>
             <th class="text-center">YEAR </th>
             <th class="text-center">NO. OF REGISTERED STUDENTS</th>
             <th class="text-center">NO. OF STUDENS PASSED</th>
             <th class="text-center">PASS PERCENTAGE</th>
             <th class="text-center">REMARKS</th>

         </tr>
     </thead>
     <tbody class="text-center">
         <tr>
             <td>1</td>
             <td>2022</td>
             <td>129 </td>
             <td>129 </td>
             <td>100% </td>
             <td>-</td>
         </tr>
         <tr>
             <td>2</td>
             <td>2023</td>
             <td>171 </td>
             <td>168 </td>
             <td>98.2% </td>
             <td>-</td>
         </tr>
     </tbody>
 </table>
</div>   */}



<div class="clr20"></div>
<p><b>D: STAFF (TEACHING)</b></p>
<div class="tablescroll table-responsive">
   <table class="table table-bordered table-striped">
     <thead>
         <tr>
             <th class="text-center">S. No. </th>
             <th class="text-center">INFORMATION </th>
             <th class="text-center">DETAILS</th>
         </tr>
     </thead>
     <tbody class="text-center">
         <tr>
             <td>1</td>
             <td>PRINCIPAL</td>
             <td>-</td>
         </tr>
         <tr>
             <td></td>
             <td>TOTAL NO. OF TEACHERS</td>
             <td>-</td>
         </tr>
         <tr>
             <td rowspan="3">2</td>
             <td>PGT</td>
             <td>-</td>
         </tr>
         <tr>
             <td>TGT</td>
             <td> -</td>
         </tr>
         <tr>
             <td> PRT </td>
             <td>-</td>
         </tr>
         <tr>
             <td>3 </td>
             <td>TEACHERS SECTION RATIO </td>
             <td> -</td>
         </tr>
         <tr>
             <td>4 </td>
             <td>DETAILS OF SPECIAL EDUCATOR </td>
             <td>-</td>
         </tr>
         <tr>
             <td>5 </td>
             <td> DETAILS OF COUNSELLOR AND WELNESS TEACHER </td>
             <td>-</td>
         </tr>
        
     </tbody>
 </table>
</div>
<div class="clr20"></div>
<p><b>E: SCHOOL INFRASTRUCTURE</b></p>
<div class="tablescroll table-responsive">
   <table class="table table-bordered table-striped">
     <thead>
         <tr>
             <th class="text-center">S. No. </th>
             <th class="text-center">INFORMATION </th>
             <th class="text-center">DETAILS</th>
         </tr>
     </thead>
     <tbody class="text-center">
         <tr>
             <td>1</td>
             <td>TOTAL CAMPUS AREA OF THE SCHOOL (IN SQUARE MTR) </td>
             <td>-</td>
         </tr>
         <tr>
             <td>2</td>
             <td> NO. AND SIZE OF THE CLASS ROOMS (IN SQUARE MTR)</td>
             <td> -</td>
         </tr>
         <tr>
             <td>3</td>
             <td>NO OF LABORATORIES INCLUDE COMPUTER LAB (IN SQUARE MTR)</td>
            
             
             <td>-</td>
         </tr>
         <tr>
             <td>4 </td>
             <td>INTERNET FACILITY (Y/N)</td>
             <td> -</td>
         </tr>
         <tr>
             <td>5</td>
             <td> NO. OF GIRLS TOILETS</td>
             <td> -</td>
         </tr>
         <tr>
             <td>6</td>
             <td> NO. OF BOYS TOILETS</td>
             <td> -</td>
         </tr>
         <tr>
             <td>7</td>
             <td>LINK OF YOUTUBE VIDEO OF THE INSPECTION OF SCHOOL COVERING THE INFRASTRUCTURE OF THE SCHOOL</td>
             <td><Link to="#" target="_blank">-</Link></td>
         </tr>
     </tbody>
 </table>
</div>
</div>  
        </div>
   
                </section>
    <Footer/>
   </>
  )
}

export default Cbse
