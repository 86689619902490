import Home from'./Pages/Home'
import Tenders from'./Pages/Tenders'
import Pphelp from'./Pages/Pphelp'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Cbse from'./Pages/Cbse'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
function App() {
  return (
      <>
      <BrowserRouter>
      <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/Tenders" element={<Tenders/>} />
       <Route path="/Pphelp" element={<Pphelp/>} />
       <Route path="/Cbse" element={<Cbse/>} />
      </Routes>
     </BrowserRouter>
      </>
  )
}

export default App;
