import React from 'react'
import Header from'../Component/Header'
import Footer from '../Component/Footer'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const Tenders = () => {
  return (
   <>
    <Header/>
    <section class="inr-slider">
                <div class="container-fluid">
                </div>
     </section>
     <section class="quicklink">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <ul>
                            <li><Link to="/">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/DPPSK/public/Images/home.png" class="img-fluid" alt=" Delhi Police Public School"/></LazyLoad>
                                </Link></li>
                             
                            <li><Link to="Tenders" class="active">Tenders</Link></li> 
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="inrhgt campus">
            <div class="breadcrumb">
                <ul class="list-inline">
                    <li><Link to="/">Home</Link></li>
                    <li>Useful Links</li>
                    <li>Tenders</li>
                </ul>
            </div>
            <div class="row">
                <div class="col-12">
                    <h2 class="mainhead">Tenders</h2> 
                     <div class="chooseyear">
                        <select id="chooseddlYear">
                           <option selected="" value="Two">2023</option>
                           <option value="One">2022</option>
                        </select>
                     </div>
                    </div>
                    <div class="row">
                    <div class="col-12">
                        <div class="tablescroll table-responsive">
                    <table class="table table-bordered table-striped">
                        <thead>
                            <tr class="text-center">
                                <th width="100px">Date</th>
                                <th>Details</th>
                                <th width="150px">Status	</th>
                            </tr>
                        </thead>
                        <tbody>
                      
                         
                             <tr>
                                <td><b>05 July</b></td>
                                <td>Physics I Tender  </td>
                                <td class="text-center"> <Link to="https://webapi.entab.info/api/image/DPPSK/public/pdf/Physics-tender-I-05-July.pdf" target="_blank">View More <i class="bi bi-download"></i></Link> </td>
                            </tr> 
                            <tr>
                                <td><b>05 July </b></td>
                                <td>Physics II Tender</td>
                                <td class="text-center"> <Link to="https://webapi.entab.info/api/image/DPPSK/public/pdf/Physics-tender-II-05-July.pdf" target="_blank">View More <i class="bi bi-download"></i></Link> </td>
                            </tr>
   <tr>
                                <td><b>01 July</b></td>
                                <td>Physics III Tender </td>
                                <td class="text-center"> <Link to="https://webapi.entab.info/api/image/DPPSK/public/pdf/Physics-Tender-III-18-July.pdf" target="_blank">View More <i class="bi bi-download"></i></Link> </td>
                            </tr>
                             <tr>
                                <td><b>18 July</b></td>
                                <td>Physics IV Tender  </td>
                                <td class="text-center"> <Link to="https://webapi.entab.info/api/image/DPPSK/public/pdf/Physics-Tender-IV.pdf" target="_blank">View More <i class="bi bi-download"></i></Link> </td>
                            </tr>



                            <tr>
                                <td><b>01 July</b></td>
                                <td>Bio Lab I Tender  </td>
                                <td class="text-center"> <Link to="https://webapi.entab.info/api/image/DPPSK/public/pdf/bio-lab-tender-01-July.pdf" target="_blank">View More <i class="bi bi-download"></i></Link> </td>
                            </tr>

                            <tr>
                                <td><b>01 July</b></td>
                                <td>Bio  Lab II Tender  </td>
                                <td class="text-center"> <Link to="https://webapi.entab.info/api/image/DPPSK/public/pdf/bio-lab-tender-01-July-II.pdf" target="_blank">View More <i class="bi bi-download"></i></Link> </td>
                            </tr>
                            
                      
                        </tbody>
                    </table>
                </div>
                    </div>
                </div>
                 
                </div>
                </section>
    <Footer/>
   </>
  )
}

export default Tenders
