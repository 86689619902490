import React, { useState, useEffect } from 'react';

function Calender() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [monthEvents, setMonthEvents] = useState([]);
  const [monthName, setMonthName] = useState('');

//   const [data, setData] = useState([]);
//   useEffect(() => {
//     const getData = async() => {

//     }
//     getData();
//   }, []);

//   useEffect(()=>{
//     if(data.length>0){
//       const monthEventsData = data.map((event)=>{return{date:new Date(event?.date).getDate(),event:event?.title}})
//       setMonthEvents(monthEventsData);
//     }
//   },[])

//   console.log(data)

  useEffect(() => {
    createCalendar(currentDate);
  }, [currentDate]);

  const createCalendar = (date) => {
    console.log(date)
    const monthEventsData = [
      { date: 21, event: 'Yoga Day' },

     ];

   



    const monthName = date.toLocaleString('en-US', { month: 'long' });
    const yearNum = date.toLocaleString('en-US', { year: 'numeric' });
    setMonthEvents(monthEventsData);

    document.getElementById('month-name').innerHTML = `${monthName} ${yearNum}`;
    
  };

  const changeMonth = (direction) => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + direction);
    console.log(newDate);
    setCurrentDate(newDate);
  };

  const handleDayClick = (day) => {
    console.log('Clicked on day:', day);
    // You can handle the click event here, like showing details or navigating to a specific date's events
  };

  const getDaysInMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
  
    // Find the day of the week for the first day of the month
    const firstDayOfWeek = new Date(year, month, 1).getDay();
  
    // If the first day of the month is not Sunday (0), render empty cells for preceding days
    const precedingDays = firstDayOfWeek !== 0 ? Array.from({ length: firstDayOfWeek }, (_, index) => '') : [];
  
    // Render the days of the month
    const daysArray = Array.from({ length: daysInMonth }, (_, index) => index + 1);
  
    // Combine the preceding days and days of the month
    return [...precedingDays, ...daysArray];
  };
  

  return (
    <div className="event-cal">
      <div className="main-wrapper">
        <div className="sidebar-title">
          <h4>Today</h4>
          <h5 id="eventDayName">{new Date().toDateString()}</h5>
        </div>
        <div className="content-wrapper grey lighten-3">
          <div className="calendar-wrapper z-depth-2">
            <div className="header-background">
              <div className="calendar-header">
                <button className="prev-button" onClick={() => changeMonth(-1)}>
                <i class="fa-solid fa-arrow-left"></i>
                </button>
                <button className="next-button" onClick={() => changeMonth(1)}>
                <i class="fa-solid fa-arrow-right"></i>
                </button>
                <div className="row header-title">
                  <div className="header-text">
                    <h3 id="month-name">February</h3>
                    <h5 id="todayDayName">Today is {currentDate.toLocaleString('en-US', { weekday: 'long', day: 'numeric', month: 'short' })}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="calendar-content">
              <div id="calendar-table" className="calendar-cells">
                <div id="table-header" className="row">
                <div className="col red">Sun</div>
                  <div className="col">Mon</div>
                  <div className="col">Tue</div>
                  <div className="col">Wed</div>
                  <div className="col">Thu</div>
                  <div className="col">Fri</div>
                  <div className="col">Sat</div>
                </div>
                <div id="table-body" className="row">
  {getDaysInMonth(currentDate).map((day, index) => (
    <div key={index} className="col" onClick={() => handleDayClick(day)}>
      {day}
      {monthEvents?.find(event => event.date === day) && <span className="day-event">•</span>}
    </div>
  ))}
</div>
              </div>
            </div>
            <div class="calendar-footer">
            <div class="emptyForm eventlist" id="emptyForm">              
                 <p><span>21:</span> Yoga Day</p>  
       
             
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Calender;
